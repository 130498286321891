.delete-modal {
    background-color: #fff;
    min-width: 440px;
    min-height: 220px;
    width: fit-content;
    height: fit-content;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    .container {
        button {
            min-width: 100%!important;
        }
    }
    .questionary-title {
        font-weight: 500;
    }
}