.startEndInstallationInputs{
  .clearDate{
    top: 50%;
  }
  .pinLogoForMeasure{
    height: calc(100% - 26px);
  }
}
.file-input-wrapper{
  padding: 0 10px;
}

.installationContainer{
  .mapContainer{
    width: 100%;
    height: 80vh;
    overflow: hidden;
    .map{
      width: 100%;
      height: 100%;
    }
  }
  .leaflet-control-attribution{
    display: none;
  }
}

.allInstallationFiles{
  max-height: 200px;
  overflow: auto;
  margin-top: 60px;
}