.textarea {
    width: 100%;
    height: 150px;
    font-size: 16px;
    box-sizing: border-box;
    border: 1px solid var(--light-blue);
    resize: none;
    outline: 0;
    transition: border-color linear 0.2s;
    border-radius: 10px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 15px;
    color: var(--main);
    font-weight: 500;
    top: 0;

    &:focus,
    &:hover {
        box-shadow: 0px 0px 5px 1px rgba(165,201,255,1);
        -webkit-box-shadow: 0px 0px 5px 1px rgba(165,201,255,1);
        -moz-box-shadow: 0px 0px 5px 1px rgba(165,201,255,1);
        transition: border-color linear 0.2s;;
    }
}
