.timeTrackerContainer{
  display: flex;
  align-items: center;
  background-color: #F6F9FF;
  border: solid 1px var(--blue);
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  position: relative;
  &.hasTitle{
    padding: 15px 10px 5px 10px;
  }
  .timeTrackerTitle{
    position: absolute;
    top: 3px;
    left: 10px;
    font-size: 12px;
  }
  .time{
    font-size: 16px;
    font-weight: 500;
  }
  .timeButton{
    color: white;
    background-color: var(--dark-blue);
    width: 25px;
    height: 25px;
    margin: 0 0 0 5px;
    font-size: 15px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}