.mikaTextAreaWrapper{
  min-height: 50px;
  margin-bottom: 20px;
  position: relative;
  textarea{
    width: 100%;
    min-height: 150px;
    font-size: 16px;
    color: var(--main);
    padding: 15px;
    &:focus{
      outline: none;
    }
  }
}