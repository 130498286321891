@import '../../App.scss';

.MuiToolbar-root{
    @include breakpoint(xs){
        flex-wrap: wrap;
    }

    .MuiToolbar-root:first-child{
        @include breakpoint(xs){
          width: 100%;
        }
    }
    div:nth-child(2){
        @include breakpoint(xs){
          display: none;
        }
      }

      div:nth-child(3){
        @include breakpoint(xs){
          padding-left: 0;
          width: 80%;
        }
      }

      div:nth-child(4){
        @include breakpoint(xs){
          width: 20%;
        }
      }
}

.archivedResponsibility{
  .adminCommentModalWrapper{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 150vw;
    height: 150vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 99999999999999;
    .adminCommentModal{
      background-color: white;
      position: relative;
      border-radius: 15px;
      padding: 30px 40px;
      min-width: 40vw;
      .textarea-wrapper{
        margin: 0!important;
      }
      .x-sign{
        position: absolute;
        top: 20px;
        right: 40px;
        transform: rotate(45deg);
        cursor: pointer;
      }
      .title{
        font-size: 30px;
      }
    }
  }
  .returnMaterialInProcessButtons{
    &:first-child{
      border-radius: 10px 0 0 10px !important;
      border-right: none !important;
    }
    &:last-child{
      border-radius: 0 10px 10px 0 !important;
      border-left: none !important;
    }
  }
}

.productFilesModalWrapper{
  position: fixed;
  top: 50%;
  width: 120vw;
  height: 120vh;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1111111111;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  .productFilesModal{
    position: relative;
    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 20px;
    .x-sign{
      position: absolute;
      top: 20px;
      right: 20px;
      transform: rotate(45deg);
      cursor: pointer;
    }
  }
}