.modalComponent {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99999 !important;
    .modalBody {
        position:fixed;
        background: white;
        width: 60%;
        height: auto;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
        border-radius: 15px;
        .modalChildren {
            display: flex;
            justify-content: center;
            padding: 40px 0;
        }
    }

}
.modalHeaderComponent {
    display: flex;
    justify-content: space-between;
    position: relative;
    // border-bottom: 1px solid rgba(182, 182, 182, 0.678);
    button {
        position: absolute;
        background: transparent;
        border: 0;
        top: 10px;
        right: 6px;
        cursor: pointer;
    }
}
.modalFooterComponent {
    display: flex;
    justify-content: space-between;
    // border-top: 1px solid rgba(182, 182, 182, 0.678);
}

.displayBlock {
    display: flex!important;
}

.displayNone {
    display: none!important;
}