.agreements {
  .photo-list {
    text-align: left;
    font-size: 18px;
    color: var(--dark-blue);
    text-transform: uppercase;
    font-weight: bold;
  }
  .agreement-creation-form {
    h1.main-title {
      font-size: 18px;
      color: var(--dark-blue);
    }
    .input-container {
      .input-wrapper {
        label {
          text-transform: uppercase;
          color: var(--dark-blue);
          font-weight: 500;
        }
      }
    }
  }
  .create-agreements-form {
    flex-wrap: wrap;
    .input-wrapper {
      width: 500px;
    }
  }
  .file-input-container {
    width: 46.5%;
    div.row {
      .input-file-wrapper {
        p {
          width: 50%;
          text-transform: uppercase;
          color: var(--dark-blue);
          font-weight: 500;
        }
        .file-input {
          background: none;
          position: relative;
          .InputFile_inputFileButton__1-sHh {
            display: none;
          }
          .InputFile_inputFileName__2-614 {
            width: 100%;
            text-align: left;
          }
        }
      }
    }
  }
  .agreement-status-dropdown {
    height: 50px;
  }
  .simple-text {
    font-size: 16px;
    text-transform: uppercase;
    color: var(--dark-blue);
    font-weight: 500;
  }
}
.print-container {
  .printableAgreement {
    display: none;
  }
}
.printableAgreement-wrapper {
  width: 100%;
  height: 100%;
  padding: 20px;
  .pdfTitle {
    font-size: 38px;
    color: var(--dark-blue);
    font-weight: 600;
  }
  .agreementContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .agreementField {
      width: 48%;
      margin-top: 50px;
      border-bottom: solid 2px var(--dark-blue);
      padding: 10px;
      font-size: 18px;
      position: relative;
      p {
        margin: 0;
        &.fieldLabel {
          width: 100%;
          margin-left: -10px;
          font-weight: 500;
        }
      }
    }
    .commentField {
      width: 100%;
      &.fieldLabel {
        margin: 30px 0 20px 10px;
        font-weight: 500;
        font-size: 18px;
      }
      &:not(.fieldLabel) {
        padding: 10px;
        border-radius: 10px;
        border: solid 2px var(--dark-blue);
        min-height: 300px;
      }
    }
  }
}
.printButton {
  border: none;
  background-color: transparent;
  font-size: 18px;
  color: var(--dark-blue);
  img {
    width: 20px;
    height: 20px;
  }
}

.m-bottom {
  margin-bottom: 40px;
}
.test {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table .tr .th,
.table .tr .td {
  width: 23%;
  min-width: 10%;
}

.small-tablet-width-for-agreements{
  @media (min-width: 768px) and (max-width: 920px) {
    width: 200px;
  }
}
