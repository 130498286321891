@import '../../App.scss';

.production-container{
    height: 100%;
    padding: 14px 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
    .button-container{
        position: absolute;
        right: 20px;
        top: 10px;
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: space-between;

        @include breakpoint(xs){
            top: 14px;
            left: 20px;
        }
        a{
            padding: 5px 20px;
            background-color: var(--dark-blue);
            color: white !important;
            text-decoration: none !important;
            border-radius: 5px;
        }
    }
}

.responsibilitySliderContainer{
    margin: 50px 0;
    .title{
        color: #34366D;
        font-size: 22px;
        font-weight: bold;
        padding-left: 30px;
    }
    .responsibilitySlider{
        margin-bottom: 200px;
        .slick-arrow{
            display: block;
            width: 45px;
            height: 45px;
            bottom: -40px;
            z-index: 2;
            &::before{
                content: url(../../assets/icons/Icon_awesome_arrow_alt_circle_right.svg);
                width: 100%;
                font-size: 45px;
                opacity: 1;
                pointer-events: none;
            }
        }
        .slick-prev{
            left: 25%;
            bottom: -90px;
            top: unset;
        }
        .slick-next{
            transform: rotate(180deg);
            right: 25%;
            bottom: -68px;
            top: unset;
        }
        .slick-dots{
            z-index: 1;
            margin: 30px auto;
            bottom: unset;
            li{
                width: 25px;
                height: 25px;
                margin-inline: 35px;
                button{
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    &::before{
                        width: 25px;
                        height: 25px;
                        background-color: #848485;
                        border-radius: 50%;
                        color: transparent;
                    }
                }
                &.slick-active{
                    button{
                        padding: 0;
                        width: 100%;
                        height: 100%;
                        &::before{
                            width: 25px;
                            height: 25px;
                            background-color: #34366D;
                            color: transparent;
                        }
                    }
                }
            }
        }
    }
    .responsibilitySlide{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .responsibilityTitle{
        width: 300px;
        margin: auto;
        font-size: 18px;
        font-weight: bold;
        color: var(--dark-blue);
    }
    .custom-card{
        width: 330px;
        margin-left: auto;
        margin-right: auto;
        height: 260px;
        background-color: white;
        border-radius: 10px;
        padding: 15px;
        box-shadow: 0px 0px 16px #18327314;
        overflow: hidden;
    }
    .responsibility-user-container{
        width: 97%;
        height: 100%;
        margin: 0 auto;
        .responsibility-user{
            display: flex;
            align-items: center;
            padding: 5px 0;
            &:not(:last-child){
                border-bottom: solid 1px var(--light-blue);
            }
            &.not-available{
                border-radius: 5px;
                padding: 15px !important;
                background-color: rgba(128, 128, 128, 0.301);
                .input-wrapper{
                    display: none;
                }
            }
            .input-wrapper{
                align-items: center;
                label{
                    i{
                        border: solid 2px var(--light-blue) !important;
                    }
                }
            }
            .input-wrapper , p{
                margin: 0;
                color: var(--main);
                font-weight: bold;
                font-size: 18px;
                &:not(.input-wrapper){
                    padding-left: 10px;
                }
            }
        }
    }
}

.product-statuses-container{
    height: 100%;
    .title{
        color: #34366D;
        font-size: 18px;
        font-weight: bold;
        display: none;
        text-transform: uppercase;
        margin-block: 20px;

        @include breakpoint(sm){
            width: 200px;
            margin-block: 5px;
        }

        @include breakpoint(xs){
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-top: 15%;
        }
    }

    .mobileAlign{
        @include breakpoint(xs){

        }
    }
    .status-table{
        width: fit-content;
        max-width: 100%;
        height: 96%;
        @media screen and (max-width: 1300px) {
            height: calc(90% - 80px);
        }
        background: #fff;
        box-shadow: 2px 8px 16px #18327314;
        padding: 0;
        border-radius: 5px;
        //overflow-y: hidden;
        overflow: overlay;
        &::-webkit-scrollbar{
            height: 15px;
            width: 15px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--dark-blue);
            border-radius: 10px;
            transition: 200ms;
            &:hover{
                background-color: var(--purple);
            }
        }
        .status-table-header{
            width: 100%;
            display: flex;
            .sortOptions{
                width: fit-content;
                display: flex;
                align-items: end;
                height: 70px;
            }
            .header-title{
                //width: 120px;
                //min-width: 120px;
                font-size: 18px;
                font-weight: bold;
                color: #34366D;
                display: flex;
                justify-content: center;
                align-items: center;
                //margin-top: 20px;
                &.client , &.product{
                    justify-content: flex-start;
                    width: 120px;
                    min-width: 120px;
                    p {
                        text-align: left;
                    }
                }
                &.product.notAdmin{
                    //width: 250px;
                    //min-width: 250px;
                }
            }
        }
        .status-table-content{
            background-color: white;
            width: fit-content;
            min-width: 100%;
            height: fit-content;
            min-height: 100%;
            border-radius: 16px;
            padding: 5px;
            animation: forwards fadeIn 2000ms;

            @keyframes fadeIn {
                0% {
                    opacity: 0;
                }
                100%{
                    opacity: 1;
                }
            }
            .status-row{
                &.clickable {
                    cursor: pointer;
                    user-select: none;
                }
                display: flex;
                padding: 5px 0;
                border-bottom: solid 1px var(--light-blue);
                &.noBorder{
                    border-bottom: none;
                    &:last-child{
                        border-bottom: solid 1px var(--light-blue);
                        border-top: none;
                    }
                }
                &.opacityLow{
                    background-color: white;
                    position: relative;
                    overflow: hidden;
                    &::before{
                        content: 'Matja fillestare, nuk mund te punosh ne kete projekt ende!';
                        background-color: #f2d6d6;
                        padding: 8px 10px;
                        color: #3f3f3f;
                        font-weight: 500;
                        border-radius: 10px;
                        width: fit-content;
                        position: absolute;
                        top: 50%;
                        z-index: 1;
                        left: 480px;
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }
                    .toggleArrow {
                        display: none !important;
                    }
                    .status-container{
                        opacity: 0;
                        pointer-events: none;
                    }
                }
                &:first-child{
                    border-top: none;
                }
                .client-name, 
                .product-name{
                    position: relative;
                    padding-right: 10px;
                    .toggleArrow{
                        position: absolute;
                        top: 50%;
                        right: -30px;
                        transform: translateY(-50%);
                        width: 25px;
                        transition: 200ms;
                        cursor: pointer;
                        &.toggled{
                            transform: translateY(-50%) rotate(180deg);
                        }
                    }
                    &:not(.client-name):nth-child(4){
                        margin-right: 40px;
                    }
                    &:not(.product-name){
                        width: 100px;
                        min-width: 100px;
                        position: relative;
                        &.nameContainer{
                            cursor: pointer;
                            transition: 200ms;
                            &:hover{
                                &::before{
                                    content:'';
                                    background: var(--dark-blue);
                                    left: 0;
                                    border-radius: 5px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    height: 40px;
                                    width: 100%;
                                    position: absolute;
                                    z-index: 1;
                                }
                                p{
                                    color: white;
                                    position: relative;
                                    z-index: 2;
                                }
                            }
                        }
                    }
                    max-width: 180px;
                    min-width: 180px;
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    color: var(--main);
                    font-weight: bold;
                    p {
                        text-align: left;
                        font-size: 14px;
                    }
                }
                .product-name{
                    &.project-name{
                        width: 120px;
                        min-width: 120px;
                    }
                }
            }
        }
    }
    .status-container{
        display: flex;
        justify-content: center;
        align-items: start;
        .materialContainer{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            .status-box{
                margin-bottom: 5px;
            }
            .createTemporaryMaterialStatus{
                height: 35px;
                width: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;
                border-radius: 10px;
                border: solid 1px;
                cursor: pointer;
                transition: 100ms;
                position: relative;
            }
        }
        .status-box{
            height: 35px;
            width: 80px;
            min-width: 80px;
            //margin: 5px 5px 0px 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            border-radius: 8px;
            border: solid 1px;
            cursor: pointer;
            transition: 100ms;
            position: relative;
            &.isValid{
                &.toDo{
                    box-shadow:inset 0px 0px 8px  var(--blue);
                }
                &.inProcess{
                    box-shadow: inset 0px 0px 8px  var(--yellow);
                }
                &.paused{
                    box-shadow: inset  0px 0px 8px  var(--orange);
                }
                &.otherWork{
                    box-shadow: inset  0px 0px 8px  var(--blueish);
                }
                &.late{
                    box-shadow: inset  0px 0px 8px var(--red);
                }
            }
            p{
                padding: 0 5px;
                font-size: 12px;
            }
            &.hidden{
                opacity: 0;
                pointer-events: none;
            }
            &.noStatus{
                opacity: 0.2;
                pointer-events: none;
            }
            &.completed{
                &.selected{
                    box-shadow:0px 0px 25px var(--green);
                }
                background-color: #EAF8E8;
                color: var(--green);
                border-color: var(--green);
                .info-container{
                    border-color: var(--green);
                }
            }
            &.toDo{
                &.simpleUser{
                    display: none !important;
                }
                &.selected{
                    box-shadow:0px 0px 25px  var(--blue);
                }
                background-color: #F6F9FF;
                color:  var(--blue);
                border-color:  var(--blue);
                .info-container{
                    border-color:  var(--blue);
                }
            }
            &.late{
                &.selected{
                    box-shadow:0px 0px 25px var(--red);
                }
                background-color: #FAE7E7;
                color: var(--red);
                border-color: var(--red);
                .info-container{
                    border-color: var(--red);
                }
            }
            &.inProcess{
                &.selected{
                    box-shadow:0px 0px 25px var(--yellow);
                }
                background-color: #FCFCE5;
                color: var(--yellow);
                border-color: var(--yellow);
                .info-container{
                    border-color: var(--yellow);
                }
            }
            &.paused{
                &.selected{
                    box-shadow:0px 0px 25px var(--orange);
                }
                background-color: #FCFCE5;
                color: var(--orange);
                border-color: var(--orange);
                .info-container{
                    border-color: var(--orange);
                }
            }
            &.otherWork{
                &.selected{
                    box-shadow:0px 0px 25px var(--blueish);
                }
                background-color: var();
                color: var(--blueish);
                border-color: var(--blueish);
                .info-container{
                    border-color: var(--blueish);
                }
            }
            .info-container{
                border: solid 1px;
                width: 20px;
                height: 20px;
                border-radius:50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
    .update-status-modal-container{
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%,-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 111vw;
        min-height: 111vh;
        background-color: rgba(0, 0, 0, 0.3);
        .update-status-modal{
            .titleAndTrackerContainer{
                .infoDatesAndTimesContainer{
                    position: relative;
                    cursor: pointer;
                    margin: 0 20px;
                    .infoIconContainer{
                        background-color: var(--light-blue);
                        color: var(--dark-blue);
                        width: 25px;
                        height: 25px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: solid 1px var(--light-blue);
                        cursor: pointer;
                    }
                    .datesAndTimesContainer.hidden{
                        pointer-events: none;
                        opacity: 0;
                    }
                    .datesAndTimesContainer{
                        width: fit-content;
                        min-width: 170px;
                        display: flex;
                        flex-direction: column;
                        position: absolute;
                        opacity: 1;
                        transition: 200ms;
                        top: 70%;
                        left: 70%;
                        z-index: 1;
                        border-radius: 8px;
                        border: solid 1px var(--dark-blue);
                        background-color: white;
                        .dates{
                            padding: 10px;
                            font-size: 14px;
                            span{
                                font-size: 12px;
                                font-weight: 700;
                            }
                        }

                    }
                }
                .selectedPauseReasonContainer{
                    width: 250px;
                    margin-left: auto;
                    margin-right: 50px;
                    .select-wrapper.orange.paused{
                        background-color: var(--orange);
                        .selected-value-container{
                            box-shadow:0px 0px 25px  var(--orange);
                        }
                    }
                }
                p{
                    margin: 0;
                    width: fit-content;
                }
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            .modal-title{
                font-size: 18px;
                font-weight: bold;
                text-transform: uppercase;
            }
            width: 80%;
            min-height: 100px;
            max-height: 90vh;
            background-color: white;
            border-radius: 10px;
            padding: 30px;
            position: relative;
            overflow: auto;
            @include breakpoint(xs){
                width: 95vw;
                padding: 20px;
                max-height: 90vh;
                overflow: auto;
            }
            .x-sign{
                position: absolute;
                cursor: pointer;
                top: 30px;
                right: 30px;
                width: 33px;
                height: 33px;
                img{
                    width: 100%;
                    height: 100%;
                    transform: rotate(45deg);
                    object-fit: cover;
                }
            }
            .materialsContainer{
                display: flex;
                padding: 30px 0px;
                .material{
                    width: fit-content;
                }
                .input-wrapper{
                    width: fit-content;
                    margin: 0 30px 0 0;
                    flex-direction: row-reverse;
                    flex-wrap: nowrap;
                    align-items: center;
                    background-color: #F6F9FF;
                    color: var(--blue);
                    border-color: var(--blue);
                    border: solid 1px;
                    padding: 10px 18px;
                    border-radius: 10px;
                    position: relative;
                    label:first-child{
                        font-size: 18px;
                        margin-left: 5px;
                        margin-top: 1px;
                        width: fit-content;
                        p{
                            width: fit-content;
                        }
                    }
                    label:not(:first-child){
                        position: static;
                        width: 0;
                        height: 0;
                        input{
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            opacity: 0;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        i{
                            position: absolute;
                            top: 50%;
                            left: 20px;
                            transform: translate(-50%, -50%);
                        }
                    }
                }
            }
            .status-container{
                width: 100%;
                display: flex;
                justify-content: space-around;
                padding: 40px 0;
                @include breakpoint(xs){
                    flex-wrap: wrap;
                }
                .status-box-holder{
                    @include breakpoint(xs){
                        width: 175px;
                        margin: 10px 0;
                    }
                    width: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    p{
                        font-weight: 500;
                        margin-top: 20px;
                    }
                }
            }
            .comment-section{
                margin-bottom: 20px;
                p{
                    font-size: 18px;
                    text-transform: uppercase;
                    font-weight: 500;
                }
            }
        }
    }
    .col-margin {
        padding: 0 1px;
        p {
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-align: center;
        }
    }
    .searchByClientContainer{
        position: absolute;
        top: 5px;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px var(--dark-blue);
        border-radius: 8px;
        overflow: hidden;
        .searchIcon{
            cursor: pointer;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--light-blue);
            transition: 200ms;
            &:hover{
                background-color: var(--dark-blue);
                color: white;
            }
        }
        .searchBar{
            border: none;
            padding: 5px 10px;
            height: 100%;
        }

        @include breakpoint(xs){
            position: relative;
            top: 0;
            right: 0;
            margin: 5px;
        }
        .input-wrapper{
            margin: 0;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            div{
                span{
                    top: 12px !important;
                }
            }
            input{
                height: 40px;
            }
            label{
                margin-right: 10px;
            }
            *{
                width: fit-content;
            }
        }
    }
}
.warehouseQuantity{
    position: absolute;
    z-index: 1;
    margin: 0;
    top: 100%;
    font-size: 14px;
    color: salmon;
    left: 0;
    width: fit-content;
}
.updatedDates{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.allFileContainer{
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    .blueprint-file-container{
        position: relative;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
        a{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .deleteButton{
            cursor: pointer;
            transform: rotate(45deg);
        }
    }
}
.adminWarningSign{
    cursor: pointer;
    position: absolute;
    top: -1px;
    right: -1px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: rgb(253, 109, 95);
    color: white;
    font-size: 12px;
    .fa.fa-info{
        margin-top: 2px;
    }
}
.deleteTemporaryMaterial{
    cursor: pointer;
    position: absolute;
    top: calc(100% - 20px);
    right: 0px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: rgb(253, 109, 95);
    color: white;
    font-size: 12px;
    .fa.fa-info{
        margin-top: 2px;
    }
}
.editTemporaryMaterial{
    cursor: pointer;
    position: absolute;
    top: calc(100% - 20px);
    left: 0px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: rgb(129, 179, 255);
    color: white;
    font-size: 12px;
    .fa.fa-info{
        margin-top: 2px;
    }
}
.quantityWrapper{
    position: relative;
    p.m-0{
        width: 85% !important;
    }
    .quantityContainer{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        background-color: var(--dark-blue);
        color: white;
        width: fit-content !important;
        height: fit-content !important;
        padding: 0 5px;
        border-radius: 5px;
        margin-bottom: 0;
    }
}

.departmentFilterContainer{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    .departmentFilter{
        background-color: whitesmoke;
        padding: 5px 30px;
        border-radius: 15px 15px 0 0;
        opacity: 0.4;
        transition: 200ms;
        cursor: pointer;
        z-index: 1;
        position: relative;
        &.active{
            opacity: 1;
            font-weight: 500;
            z-index: 2;
            box-shadow: 0 -5px 10px 0px rgba(0, 0, 255, .3);
            background-color: white;
        }
        p{
            margin: 0;
            color: var(--dark-blue);
            font-size: 20px;
        }
    }
}

.checkbox{
    max-width: 40px !important;
    min-width: 40px !important;
    min-height: 40px !important;
    max-height: 40px !important;
    &.hoverable{
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 0 !important;
        transition: 200ms;
        border-radius: 50%;
        &:hover{
            background-color: var(--dark-blue);
            svg{
                fill: white;
            }
        }
    }
}

.update-status-modal-container.selectedProductStatusRow{
    .update-status-modal{
        width: 50%;
    }
    .selectedProductStatusRowData{
        p{
            font-size: 20px;
            span{
                font-weight: bold;
            }
        }
    }
}
.progressBarContainer{
    width: 100%;
    background-color: #F6F9FF;
    border-radius: 15px;
    height: 30px;
    margin-bottom: 30px;
    .progressBar{
        position: relative;
        border-radius: 15px;
        height: 100%;
        background-color: var(--dark-blue);
        .percentageText{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            p{
                width: 100%;
                text-align: center;
                margin: 0;
                color: white;
                font-weight: 500;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

.productionFilterButton{
    position: absolute;
    top: 35px;
    right: 20px;
    width: 35px;
    height: 35px;
    z-index: 1111111111;
}

.dateContainer{
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 5px !important;
    .x-signDate{
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        right: 40px;
        cursor: pointer;
    }
    .dateValue{
        margin: 0;
        font-size: 16px;
    }
    .label{
        margin: 0;
        position: absolute;
        bottom: 80%;
        left: 5px;
        font-size: 13px;
    }
    .calendar{
        position: relative;
        cursor: pointer;
        input{
            width: 30px;
            height: 30px;
            border: none;
        }
        .calendarIcon{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;
            width: 35px;
            height: 35px;
            background-color: var(--purple);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.filterIconContainer{
    background-color: var(--purple);
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
}

.filterDropdownContainer{
    background-color: white;
    width: 300px;
    position: absolute;
    right: 5px;
    top: 100%;
    border: solid 1px var(--dark-blue);
    border-radius: 10px;
    user-select: none;
    &.hide{
        display: none;
    }
    .filterWrapper{
        position: relative;
        padding: 30px 5px 5px 5px;
        .closeFiltersButton{
            position: absolute;
            cursor: pointer;
            top: 5px;
            right: 10px;
            width: 15px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
        }
        .select-wrapper{
            margin-bottom: 30px;
        }
        .dateContainer{
            margin-bottom: 20px;
            .calendar{
                margin-top: -15px;
            }
        }
    }
}
//
//.status-box.toDo:not(.isValid, .admin){
//    display: none;
//}

.materialsWrapper.notAdmin.status-box.toDo{
    display: none;
}

.createTemporaryMaterialStatusModalWrapper{
    width: 150vw;
    height: 150vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11111;
    .createTemporaryMaterialStatusModal{
        background-color: white;
        padding: 20px 20px 0 20px;
        border-radius: 10px;
        position: relative;
        .x-sign{
            position: absolute;
            width: fit-content;
            height: fit-content;
            top: 20px;
            cursor: pointer;
            right: 20px;
            img{
                transform: rotate(-45deg);
            }
        }
    }
}
.zoomContainer{
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 1;
    background-color: var(--purple);
    display: flex;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    padding: 3px;
    div{
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: 200ms;
        border-radius: 8px;
        &:hover{
            background-color: var(--dark-blue);
            svg{
                fill: var(--purple);
            }
        }
        svg{
            width: 15px;
            height: 15px;
            fill: var(--dark-blue);
        }
    }
    .divider{
        padding: 0;
        height: 25px;
        width: 2px;
        margin: 2px;
        border-radius: 1px;
        background-color: var(--dark-blue);
    }
}
.productionFilterIconContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: auto;
    .productionFilterIcon{
        color: var(--dark-blue);
        background-color: var(--purple);
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
    }
    .productionFilterWrappers.hidden{
        display: none;
    }
    .productionFilterWrappers{
        position: absolute;
        width: 250px;
        top: 75%;
        left: 75%;
        z-index: 1;
        background-color: #f4f4f4;
        padding: 20px 5px 5px 5px;
        border-radius: 8px;
        border: solid 1px var(--dark-blue);
        .productionFilter{
            width: 100%;
            margin-top: 10px;
            &:first-child{
                margin-top: unset;
            }
        }
    }
}