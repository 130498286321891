.offers-form-wrapper {
    position: relative;
    .offer-title {
        color: var(--dark-blue);
        font-weight: 500;
    }
    .status-offer-text {
        font-size: 14px;
        color: var(--main);
        margin-bottom: 0;
    }
    .red {
        color: var(--red);
    }
    .comment-details-container{
        .input-wrapper{
            margin-top: 56px;
            margin-bottom: 0;
            height: 76.5%;
            textarea{
                height: 100%;
            }
        }
    }
    .offerStatusContainer{
        width: 50%;
        margin: 30px 0 30px 0;
    }
    .photo-list {
        text-align: left;
        font-size: 18px;
        color: var(--dark-blue);
        text-transform: uppercase;
        font-weight: bold;
    }
    .additional-offers-form{
        .input-container{
            width: 45%;
            height: max-content;
            flex-wrap: wrap;
            display: flex;
            align-items: flex-start;
            .input-wrapper{
                width: 291px;
            }
        }
    }
    .dynamic-offer-fields-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .offerField{
            width: 300px;
            margin-right: 15px;
            input{
                width: 100%;
                min-height: 50px;
                border-radius: 10px;
                margin-block: 10px 20px;
                padding: 5px 30px 5px 24px;
                border: solid 1px var(--light-blue);
                &:hover, &:focus{
                    outline: none;
                    box-shadow: 0px 0px 5px 1px var(--light-blue);
                    -webkit-box-shadow: 0px 0px 5px 1px var(--light-blue);
                    -moz-box-shadow: 0px 0px 5px 1px var(--light-blue);
                    transition: border-color linear 0.2s;
                }
            }
        }
    }
}

.button-save-offer{
    //left: calc(100% - 250px);
    width: 250px;
    height: 50px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    //top: 20px;
    background-color: #252434;
    color: white;
    border-radius: 0 !important;
    margin-top: auto !important;
}

.calculate-offer-modal-background .input-container .input-wrapper {
    min-width: 220px !important;
    max-width: 220px !important;
}

.calculate-offer-modal-background .input-container.firstRow .input-wrapper {
    min-width: 280px !important;
    max-width: 280px !important;
}

.calculate-offer-modal-background .product-wrapper .input-container .smallerWidth {
    min-width: 80px !important;
    max-width: 80px !important; 
}

.input-wrapper.smallerInput.medium {
    min-width: 120px !important;
    max-width: 120px !important;
}

.calculate-offer-modal-background .input-wrapper{
    z-index: 1;
    position: relative;
}

.calculate-offer-modal-background .input-wrapper.col-10.note {
    max-width: 80% !important;
}

img.arrowdown {
    background-color: white!important;
    transform: rotate(-90deg);
}
  
.selected-value-container p{
    white-space: nowrap;
    //width: calc(100% - 40px);
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}


.select-wrapper.designation-select.max-width-input {
    max-width: 220px;
    min-width: 220px !important;
    border-radius: 50%;
}

.calculateOfferModalButton {
    padding: 0 20px !important;
    background-color: var(--dark-blue) !important;
    color: #fff!important;
    padding-right: 0 !important;
    box-shadow: 2px 8px 16px #18327314;
    min-height: 50px;
    img{
        fill: #fff!important;
        margin: 0 15px;
    }
}

.actionsContainer{
    display: flex;
    justify-content: space-between;
    .modalButton_files-container{
        width: 50%;
    }
    .files-container{
        width: 45%;
    }
}
.offerConditions{
    width: 50%;
    padding: 15px;
    p{
        margin: 0;
    }
}

@media screen and (max-width: 500px) {
    .offerStatusContainer{
        width: 100% !important;
    }
    .dynamic-offer-fields-container{
        .offerField{
            width: 100% !important;
        }
    }
    .actionsContainer{
        flex-direction: column;
        .modalButton_files-container{
            width: 100%;
        }
        .files-container{
            width: 100%;
            margin-top: 20px;
        }
    }
    .offerConditions{
        width: 100%;
        padding: 15px;
    }
}