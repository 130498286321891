.content-wrapper {
    width: calc(100% - 250px)!important;
    height: 100vh;
    overflow-y: auto;
    padding: 0!important;
    background-color: white;
    z-index: 999;
    position: relative;
    right: 0;
    transition: 100ms;
}
.content-wrapper.collapsed {
    width: calc(100% - 65px)!important;
}
@media screen and (max-width: 500px) {
    .content-wrapper{
        width: 0 !important;
        &.collapsed {
            width: 100% !important;
        }
    }
}